import { type ReactNode } from 'react';

import { type ProfilePhotoProps, ProfilePhoto } from '../profile-photo/profile-photo';

import styles from './profile-photo-with-badge.module.scss';

export const ProfilePhotoWithBadge = ({
  badge,
  ...restProps
}: ProfilePhotoProps & { badge?: ReactNode }) => {
  return (
    <div className={styles['profile-photo']}>
      <ProfilePhoto {...restProps} />
      {badge && (
        <div className={styles['profile-photo__badge']} data-testid="profile-photo-badge">
          {badge}
        </div>
      )}
    </div>
  );
};
